import React, {Component} from 'react';
import { Bar } from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import { REACT_APP_AUTOMASJON_API_URL } from '../../appConfig';
import hentApiData from '../../automasjonApiGet';

class Soylediagram extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            soylediagramdata: {},            
            isLoaded: false,
            feiltekst: null,
            dataUri: props.dataUri,
            title: props.title           
        }
    }

    componentDidMount() {
        this.hentData()     
    }

    componentWillReceiveProps(props) {    
        if (this.props.dataUri !== props.dataUri) {      
          this.setState({
            dataUri: props.dataUri,
            title: props.title,
            isLoaded: false,
            feiltekst: null
          }, () => {
            this.hentData()
          })
        }
      }
        
    hentData() {
        hentApiData(REACT_APP_AUTOMASJON_API_URL + this.state.dataUri)        
        .then(data => {
            if (Object.entries(data).length === 0 && data.constructor === Object)
            {                     
                this.setState ({
                    feiltekst: "Ingen data å vise",
                    isLoaded: true
                })
            } else
            {                     
                this.settData(data)
            }
        })
        .catch(err => {      
            this.setState({
                feiltekst: "Henting av data feilet. Prøv igjen eller kom tilbake senere.",
                isLoaded: true
            })
        })
    }

    settData(data) {
        var labelList=[], dataList=[];
        Object.entries(data).forEach(([key,value]) => {
            labelList.push(key);
            dataList.push(value)
        });

        return (
            this.setState({
                isLoaded: true,
                feiltekst: null,
                soylediagramdata: 
                {                    
                    labels: labelList,
                    datasets: [{
                        label: this.state.title,
                        data: dataList,
                        backgroundColor: [
                            '#A1C438',
                            '#F5AD4A',
                            '#E25653',
                            '#87A8E0',
                            '#388A8D',
                            '#A07A75'
                        ],
                        borderColor: [
                            '#A1C438',
                            '#F5AD4A',
                            '#E25653',
                            '#87A8E0',
                            '#388A8D',
                            '#A07A75'
                        ],
                        borderWidth: 1
                    }]

                }              
            })
        )
    }

    render () {  
        if(!this.state.isLoaded) {
            return (
                <div>
                    <CircularProgress />
                    <Typography>Henter innhold</Typography>
                </div>
            )
        } 
        
        if (this.state.feiltekst) {
            return <Typography color="error">{this.state.feiltekst}</Typography>
        }

        return (
            <Grid container>
                <Grid item lg={8}>
                    <Bar data={this.state.soylediagramdata} />
                </Grid>
            </Grid>                
        )
        
    }
}

export default Soylediagram;

