import React, {Component} from 'react';
import { Pie} from 'react-chartjs-2';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
import { REACT_APP_AUTOMASJON_API_URL } from '../../appConfig';
import hentApiData from '../../automasjonApiGet';

class Kakediagram extends Component {    
    constructor(props) {
        super(props);        
        this.state = {
            kakediagramdata: {},            
            isLoaded: false,            
            dataUri: props.dataUri,
            dataUriAntall: props.dataUriAntall,
            title: props.title,
            feiltekst: null        
        }
    }

    componentDidMount() {        
        this.hentData()       
    }    
        
    componentWillReceiveProps(props) {
        if (this.props.dataUriAntall !== props.dataUriAntall) {      
            this.setState({        
            dataUriAntall: props.dataUriAntall,
            dataUri: props.dataUri,
            title: props.title,
            feiltekst: null,
            isLoaded: false
            }, () => {
                this.hentData()
            })
        }
    }

    hentData() {        
        hentApiData(REACT_APP_AUTOMASJON_API_URL + this.state.dataUriAntall)        
            .then(dataAntall => {                
                hentApiData(REACT_APP_AUTOMASJON_API_URL + this.state.dataUri)        
                .then(data => {
                    if (Object.entries(data).length === 0 && data.constructor === Object)
                    {                     
                        this.setState ({
                            feiltekst: "Ingen data å vise",
                            isLoaded: true
                        })
                    } else
                    {                     
                        this.settData(data, dataAntall)
                    }                    
                })
                .catch(err => {      
                    this.setState({
                        feiltekst: "Henting av data feilet. Prøv igjen eller kom tilbake senere.",
                        isLoaded: true
                    })
                })
            } 
        )        
        .catch(err => {      
            this.setState({
                feiltekst: "Henting av data feilet. Prøv igjen eller kom tilbake senere.",
                isLoaded: true
            })
        })
    }
 
    settData(data, dataAntall) {                  
        Object.entries(data).forEach(([key,value]) => {
            this.setState({
                isLoaded: true,
                feiltekst: null,                
                kakediagramdata: {
                    labels:                 
                        Object.entries(data).map(([key,value])=>{
                            return (
                                key
                            )                                    
                        }),            
                    datasets: [{
                        data: 
                            Object.entries(data).map(([key,value])=>{
                                return (
                                    value
                                )                                    
                        }),            
                        backgroundColor: [
                        '#A1C438',
                        '#F5AD4A',
                        '#E25653',
                        '#87A8E0',
                        '#388A8D',
                        '#A07A75'
                        ],                
                        hoverBackgroundColor: [
                        '#A1C438',
                        '#F5AD4A',
                        '#E25653',
                        '#87A8E0',
                        '#388A8D',
                        '#A07A75'
                        ],                
                    }]                        
                },
                options : {
                    responsive: true,                    
                    title: {
                        display: true,
                        position: "top",
                        text: dataAntall + " " + this.state.title,
                        fontSize: 18,
                        fontColor: "#111"
                    },
                    legend: {
                        display: true,
                        position: "bottom",
                        labels: {
                            fontColor: "#333",
                            fontSize: 16,
                            boxWidth: 15
                        },                
                    }
                }                
            });                                  
        })        
    }

    render () {        
        if(!this.state.isLoaded) {
            return (
                <div>
                    <CircularProgress />
                    <Typography>Henter innhold</Typography>
                </div>
            )
        } 
        
        if (this.state.feiltekst) {
            return <Typography color="error">{this.state.feiltekst}</Typography>
        }

        return <Pie data={this.state.kakediagramdata} options={this.state.options}/>
        
    }
}

export default Kakediagram;