import NorkartIdJs from 'norkartidjs2';
import { REACT_APP_APPLICATION_PROFILE, REACT_APP_CLIENT_ID } from './appConfig';

const norkartIdConfig = {
  clientId: REACT_APP_CLIENT_ID,
  postLogoutUrl: window.location,
  useApplicationBackend: true,
  profile: REACT_APP_APPLICATION_PROFILE,
};

const norkartIdJs = new NorkartIdJs(norkartIdConfig);
export default norkartIdJs;
