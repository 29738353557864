import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import flowRight from 'lodash/flowRight';
import auth from 'auth';
import authWrapper from 'authWrapper';
import UserStatus from './UserStatus';
import Logo from '../../Image/norkart_symbol_none_gradient_positiv.png'

//material UI css-in-js
//see https://material-ui.com/customization/css-in-js/
const styles = {
  flex: {
    flexGrow: 1,   
  },
  img: {
    height: '6%',
    width: '6%'
  }
};

const Header = ({
  classes,
  isAuthenticated,
  isAuthorized,
  isLoadingUserData,
  userData,
}) => (  
  <AppBar position="sticky" color="default">              
    <Toolbar>      
      <img src={Logo} alt="Norkartlogo" className={classes.img}/>
      <Typography variant="title" className={classes.flex} color="primary">Renovasjonsportal</Typography>      
      <UserStatus        
        isLoggedIn={isAuthenticated && isAuthorized}
        isLoadingUserData={isLoadingUserData}
        logIn={() => auth.logIn()}
        logOut={() => auth.logOut()}
        userData={userData}
      />      
    </Toolbar>    
  </AppBar>  
);

//combine several Higher-Order-Components,
//see https://reactjs.org/docs/higher-order-components.html#convention-maximizing-composability
const enhance = flowRight(
  authWrapper(auth),
  withStyles(styles)
);
export default enhance(Header);
