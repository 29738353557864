import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import Button from '@material-ui/core/Button';

class VisBildeDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bildeUrl: props.bildeUrl,
      buttonTitle: props.buttonTitle,
      open: false
    }
  }

  handleOpen = () => {
    this.setState({ open: true });
  };

  handleClose = () => {
    this.setState({ open: false });
  };


  componentWillReceiveProps(props) {
      this.setState({
        bildeUrl: props.bildeUrl,
        buttonTitle: props.buttonTitle     
      })    
  }

  render() {
    return (
      <div>        
        <Button id='btnVisBildeDialog' onClick = {this.handleOpen} color="primary">
          {this.state.buttonTitle}
        </Button>             
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >          
          <DialogContent>
            <img src={this.state.bildeUrl} style={{maxWidth:"100%"}} alt="Bildet kunne ikke vises"/>
          </DialogContent>
          <DialogActions>
            <Button id='btnLukkBildeDialog' onClick={this.handleClose} color="primary">
              Lukk
            </Button>            
          </DialogActions>
        </Dialog>

      </div>
    );
  }
}

export default VisBildeDialog;