import React, { Component } from 'react'
import NkaButton from 'nka-button';
import 'nka-button/dist/style.css';
import '../LandingPage/LandingPage.css';
import Logo from '../../Image/norkart_logo_gradient_positiv.png'

class LandingPage extends Component {
    constructor(props) {
        super(props);
        this.hasAuth =  !!this.props.auth;
    }

    lesMerClick() {        
        window.open('https://www.norkart.no/renovasjon/', "_blank");
    }
    
    render() {    
        return (
        <div className="nk-landing">
            <div className="nk-landing-top">
                <img src={Logo} alt="Norkart logo" className="img-resize"/>                
            </div>
            <div className="nk-landing-content">
            <h1>Renovasjonsportal</h1>
            <h2>Overvåkning av dine automatiserte prosesser.</h2>
            <p>
                Gjennom en brukertilpasset løsning tilbyr vi proffbrukere og saksbehandlere gode verktøy på en samlet arbeidsflate.
            </p>
            <p>                
                <NkaButton buttontype="secondary" size="l" onClick={this.lesMerClick}>Les mer på norkart.no</NkaButton>                
            </p>
            </div>
            <div className="nk-landing-footer"></div>
        </div>
        );
    }
}

export default LandingPage