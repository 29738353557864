import { createMuiTheme } from '@material-ui/core/styles';

//Material UI theme
//see https://material-ui.com/customization/themes/

export default createMuiTheme({
  palette: {
    primary: {
      main: '#51a026',
      contrastText: '#F6F6F6',
    },
    secondary: {
      main: '#2E2D30',
      contrastText: '#E6E6E8',
    },
    error: {
      main: '#E25653',
    },
    background: {
      default: '#E6E6E8',
      paper: '#F6F6F6',
    },
    text: {
      primary: '#000000',
      secondary: '#2E2D30',
    },
  },
  shape: {
    borderRadius: 0,
  },
  norkartComponents: {
    iconColor: 'nk-white',
  },
});
