import React from 'react';
import { REACT_APP_AUTOMASJON_API_URL } from '../../appConfig';
import Get from '../../automasjonApiGet';
import Post from '../../automasjonApiPost';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import JSONPretty from 'react-json-pretty';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Typography } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';

class VisOrdreDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      ordrenr: props.ordrenr,
      matrikkelnr: props.matrikkelnr,
      valgfriStatustekst: null,
      ordredata: null,
      open: false,
      confirmOpen: false,
      oppdatererOrdre: false,
      oppdaterOrdreSvar: null,
      isLoaded: false
    }
  }
  
  handleOpen = () => {
    this.setState({
      open: true,
      isLoaded: false });
    this.hentData();
  };

  handleConfirmOpen = () => {
    this.setState({ confirmOpen: true });
  };
  

  handleUpdate = () => {
    this.setState({
      oppdatererOrdre: true,
      oppdaterOrdreSvar: null
    });
    this.oppdaterOrdre();
  };

  handleClose = () => {
    this.setState({ open: false });
  };

  handleConfirmClose = () => {
    this.setState({ 
      confirmOpen: false,
      oppdatererOrdre: false,
      oppdaterOrdreSvar: null
    });
  };

  handleComment = valgfriStatustekst => event =>{
    this.setState({
      [valgfriStatustekst]: event.target.value
    });
  };

  componentDidMount() {
    this.hentData();
  }

  componentWillReceiveProps(props) {
      this.setState({
        ordrenr: props.ordrenr,
        matrikkelnr: props.matrikkelnr,
        valgfriStatustekst: null,
        ordredata: null
      })    
  }

  oppdaterOrdre() {

    // Kopierer objekt og setter inn ev. kommentar og status 100
    var ordrekopi = JSON.parse(JSON.stringify(this.state.ordredata));    
    ordrekopi.status = 101;
    ordrekopi.valgfriStatustekst = this.state.valgfriStatustekst    

    Post(REACT_APP_AUTOMASJON_API_URL + 'oppdaterOrdreEkstern/', ordrekopi)
    .then(data => {
      this.setState({
        oppdaterOrdreSvar: "Lagring var vellykket.",
        oppdatererOrdre: false
      })
    })
    .catch(err => {      
      this.setState({
        oppdaterOrdreSvar: "Statusoppdatering feilet. Prøv igjen senere.",
        oppdatererOrdre: false
      })
    })
  }

  hentData() {
    Get(REACT_APP_AUTOMASJON_API_URL + 'hentOrdre/' + this.state.ordrenr + '/' + this.state.matrikkelnr)
    .then(data => {      
      this.setState({
        isLoaded: true,
        ordredata: data
      });
    })
    .catch(err => console.error('Feilet:' + err))
  }

  render() {
    return (
      <div>                 
        <Button id='btnDetaljer' onClick = {this.handleOpen} color="primary">
          Se detaljer
        </Button>      
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="form-dialog-title">Ordredetaljer</DialogTitle>
          <DialogContent>
            {this.state.isLoaded ? 
              <JSONPretty id="json-pretty" data={this.state.ordredata}></JSONPretty>
              :
              <CircularProgress />
            }
          </DialogContent>
          <DialogActions>
            <Button id='btnOppdaterOrdre' onClick = {this.handleConfirmOpen} color="primary">
              Oppdater ordre
            </Button>      
            <Dialog fullWidth="true"
              open={this.state.confirmOpen}
              onClose={this.handleConfirmClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="form-dialog-title">Fullfør ordre</DialogTitle>
              <DialogContent>
                <Typography>Trykk "Fullfør ordre" for å sette ordrestatus til "Ferdig behandlet".</Typography>
                <TextField
                  id="valgfriStatustekst"
                  label="Beskrivelse (valgfritt)"
                  placeholder="Din kommentar"
                  multiline
                  margin="normal"
                  onChange={this.handleComment('valgfriStatustekst')}
                />
              </DialogContent>
              <DialogActions>                
                <Typography>{this.state.oppdaterOrdreSvar}</Typography>
                { this.state.oppdatererOrdre && <CircularProgress /> }                
                <Button id='btnFullforOrdre' onClick={this.handleUpdate} color="primary">
                  Fullfør ordre
                </Button>
                <Button id='btnAvbrytOppdaterOrdre' onClick={this.handleConfirmClose} color="primary">
                  Avbryt
                </Button>            
              </DialogActions>
            </Dialog>
            <Button id='btnLukkDetaljer' onClick={this.handleClose} color="primary">
              Lukk
            </Button>            
          </DialogActions>
        </Dialog>

      </div>
    );
  }
  
}

export default VisOrdreDialog;