import React, {Component} from 'react';
import Grid from '@material-ui/core/Grid';
import Kakediagram from '../Kakediagram/Kakediagram';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const styles = theme => ({
    root: {
      flexGrow: 1,
    },
    control: {
      padding: theme.spacing.unit * 2,
    },
    chartContainer: {
        responsive: true,
        maintainAspectRatio: false
    }
  });
  

class Avvikdiagram extends Component {
    constructor(props) {
        super(props);        
        this.state = {
            sisteAntallDager: props.sisteAntallDager
        }
    }

    componentWillReceiveProps(props) {        
    if (this.props.sisteAntallDager !== props.sisteAntallDager) {      
        this.setState({        
            sisteAntallDager: props.sisteAntallDager
            })
        }
    }

    render() {        
        const { classes } = this.props;
        
        const dataUriAvvikSkade = 'hentAvvikSkadeGruppertSisteAntallDager/' + this.state.sisteAntallDager;
        const dataUriAvvikSkadeAntall = 'hentAntallOrdretypeSisteAntallDager/BeholderAvvikSkadeInfoModel/'+ this.state.sisteAntallDager;

        const dataUriAvvikIkkeTomt = 'hentAvvikIkkeTomtGruppertSisteAntallDager/'+ this.state.sisteAntallDager;
        const dataUriAvvikIkkeTomtAntall = 'hentAntallOrdretypeSisteAntallDager/BeholderAvvikIkkeToemtInfoModel/'+ this.state.sisteAntallDager;        

        return (
            <Grid container className={classes.root}>
                <Grid item lg={6} className={classes.control}>
                    <div className={classes.chartContainer}>                    
                    <Kakediagram                    
                        dataUri={dataUriAvvikSkade}
                        dataUriAntall={dataUriAvvikSkadeAntall}
                        title='skadede beholdere'/>
                    </div>
                </Grid>
                <Grid item lg={6} className={classes.control}>
                    <Kakediagram 
                        dataUri={dataUriAvvikIkkeTomt}
                        dataUriAntall={dataUriAvvikIkkeTomtAntall}
                        title='ikke tømte beholdere'/>                                        
                </Grid>
            </Grid>
        )
    }
}

Avvikdiagram.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(Avvikdiagram);