import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import CssBaseline from '@material-ui/core/CssBaseline';
import MenuList from '@material-ui/core/MenuList';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Hidden from '@material-ui/core/Hidden';
import Divider from '@material-ui/core/Divider';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import ListItemText from '@material-ui/core/ListItemText';
import { withRouter, Link, Route, Switch } from 'react-router-dom';
import Avvikdiagram from '../Avvikdiagram/Avvikdiagram';
import Soylediagram from '../Soylediagram/Soylediagram';
import Icon from 'nka-icons';
import Nokkeltall from '../Nokkeltall/Nokkeltall'
import ReactTable from '../ReactTable/ReactTable'
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { compose } from 'recompose';

const drawerWidth = 300;

const removeShadowsTheme = createMuiTheme({
  // Må ha 25 farger for ikke å gi javascript-warning
  shadows: Array(25).fill('none')
});

const styles = theme => ({
  drawer: {    
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0
    },    
  },
  appBar: {
    marginLeft: drawerWidth,    
    backgroundColor: 'inherit',    
    [theme.breakpoints.up('sm')]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginTop: theme.spacing.unit * 2,    
    },
  },
  menuButton: {
    marginRight: 20,
    color: '#2e2d30',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  toolbar: theme.mixins.toolbar,    
  drawerPaper: {    
    width: drawerWidth,
    margin: theme.spacing.unit,        
  },
  content: {        
    padding: theme.spacing.unit * 2,    
    [theme.breakpoints.up('sm')]: {
        marginLeft: drawerWidth
    }
  },
  formControl: {
    margin: theme.spacing.unit * 2,
    minWidth: 120,
  },
});

const IngenTreff = ({ location }) => (
    <div>
        <Typography>
            Denne siden finnes ikke <code>{location.pathname}</code>      
        </Typography>
        <hr/>
        <Typography>            
            Har du bommet på adressen?
        </Typography>
    </div>
  )

const applicationPaths = {
  avvik: '/',
  innmeldtfra: '/innmeldtfra',
  innogutleveringer: '/innogutleveringer',
  feil: '/feil',
  logg: '/logg'
} 

const menyvalg = {
  avvik: 'Avvik',
  innmeldtfra: 'Henvendelser',
  innogutleveringer: 'Inn- og utleveringer',
  feil: 'Feil',
  logg: 'Hendelseslogg'
};

const subtitles = {
  avvik: 'Fordelt på skader og ikke tømt-meldinger',
  innmeldtfra: 'Totalt innkomne henvendelser til automasjon',
  innogutleveringer: 'Oversikt over inn- og utleveringer',
  feil: 'Oversikt over henvendelser som har feilet',
  logg: 'Hendelseslogg for valgt periode'
}

class Venstremeny extends React.Component {
  constructor() {    
    super();
    this.handleChangePeriode = this.handleChangePeriode.bind(this);
    this.state = {
      mobileOpen: false,
      selectedSisteAntallDagerValue: 7    
    };
  }

  hentMenyvalgTekst = (path) => {
    switch (path) {
      case applicationPaths.avvik:
        return menyvalg.avvik;
      case applicationPaths.innmeldtfra:
        return menyvalg.innmeldtfra;
      case applicationPaths.innogutleveringer:
        return menyvalg.innogutleveringer;
      case applicationPaths.feil:
        return menyvalg.feil;
      case applicationPaths.logg:
        return menyvalg.logg;    
      default:
        return 'Tekst mangler';
    }
  }

  hentMenyvalgSubtitle = (path) => {  
    switch (path) {
      case applicationPaths.avvik:
        return subtitles.avvik;
      case applicationPaths.innmeldtfra:
        return subtitles.innmeldtfra;
      case applicationPaths.innogutleveringer:
        return subtitles.innogutleveringer;
      case applicationPaths.feil:
        return subtitles.feil;
      case applicationPaths.logg:
        return subtitles.logg;    
      default:
        return 'Undertekst mangler';
    }
  }  

  handleDrawerToggle = () => {
    this.setState(state => ({ mobileOpen: !state.mobileOpen }));
  };

  handleChangePeriode = event => {    
    this.setState({
      selectedSisteAntallDagerValue: event.target.value 
      });
  };


  render() {    
    const sisteAntallDager = this.state.selectedSisteAntallDagerValue;
    const { classes, theme, location: {pathname} } = this.props;

    const drawer = (
      <div>          
        <div className={classes.toolbar} />
        <div className={classes.list} >        
        <MenuList>            
            <FormControl className={classes.formControl}>
              <InputLabel htmlFor="selectedSisteAntallDagerValue">Periode</InputLabel>
              <Select
                value={this.state.selectedSisteAntallDagerValue}
                onChange={this.handleChangePeriode}
                inputProps={{
                  name: 'selectedSisteAntallDagerTekst',
                  id: 'selectedSisteAntallDagerValue',
                }}
              >                    
                <MenuItem value={1}>Siste dag</MenuItem>
                <MenuItem value={7}>Siste uke</MenuItem>
                <MenuItem value={31}>Siste måned</MenuItem>
                <MenuItem value={365}>Siste år</MenuItem>
              </Select>
            </FormControl>                           
            <Divider />            
            <MenuItem 
                button
                selected={pathname === applicationPaths.avvik}
                component={Link}
                to={applicationPaths.avvik}>              
                <Icon icon="analyse"/>
                <ListItemText primary={this.hentMenyvalgTekst(applicationPaths.avvik)} />
                <Nokkeltall dataUri={'hentAntallAvvikSisteAntallDager/' + sisteAntallDager} color="primary"/>
            </MenuItem>
            <Divider />
            <MenuItem
                button
                selected={pathname === applicationPaths.innmeldtfra}
                component={Link}
                to={applicationPaths.innmeldtfra}>
                <Icon icon="statistikk"/>
                <ListItemText primary={this.hentMenyvalgTekst(applicationPaths.innmeldtfra)} />
                <Nokkeltall dataUri={'hentAntallOrdreSisteAntallDager/' + sisteAntallDager} color="primary"/>
            </MenuItem>
            <Divider />
            <MenuItem
                button
                selected={pathname === applicationPaths.innogutleveringer}
                component={Link}
                to={applicationPaths.innogutleveringer}>                
                <Icon icon="liste"/>
                <ListItemText primary={this.hentMenyvalgTekst(applicationPaths.innogutleveringer)} />
                <Nokkeltall dataUri={'hentAntallInnOgUtleveringerSisteAntallDager/' + sisteAntallDager} color="primary"/>
            </MenuItem>
            <Divider />
            <MenuItem
                button
                selected={pathname === applicationPaths.feil}
                component={Link}
                to={applicationPaths.feil}>
                <Icon icon="risiko"/>
                <ListItemText primary={this.hentMenyvalgTekst(applicationPaths.feil)} />
                <Nokkeltall dataUri={'hentAntallFeiletSisteAntallDager/' + sisteAntallDager} color="error"/> 
            </MenuItem>
            <Divider />  
            <MenuItem 
                button                
                selected={pathname === applicationPaths.logg}
                component={Link}
                to={applicationPaths.logg}>              
                <Icon icon="liste"/>
                <ListItemText primary={this.hentMenyvalgTekst(applicationPaths.logg)} />
            </MenuItem>
        </MenuList>
        </div>    
      </div>
    );

    return (
      <div>
        <CssBaseline />
        <MuiThemeProvider theme={removeShadowsTheme}>
        <AppBar position="absolute" className={classes.appBar}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Åpne menyen"
              onClick={this.handleDrawerToggle}
              className={classes.menuButton}
            >
                <MenuIcon />
            </IconButton>
            <Grid container direction="column">            
              <Grid item><Typography variant="title">{this.hentMenyvalgTekst(pathname)}</Typography></Grid>
              <Grid item><Typography variant="subheading">{this.hentMenyvalgSubtitle(pathname)}</Typography></Grid>
            </Grid>
          </Toolbar>
        </AppBar>
        </MuiThemeProvider>
        <nav className={classes.drawer}>
          {/* The implementation can be swap with js to avoid SEO duplication of links. */}
          <Hidden smUp implementation="css">
            <Drawer
              container={this.props.container}
              variant="temporary"
              anchor={theme.direction === 'rtl' ? 'right' : 'left'}
              open={this.state.mobileOpen}
              onClose={this.handleDrawerToggle}
              onClick={this.handleDrawerToggle}
              classes={{
                paper: classes.drawerPaper,
              }}
              ModalProps={{
                keepMounted: true, // Better open performance on mobile.
              }}
            >
              {drawer}
            </Drawer>
          </Hidden>
          <Hidden xsDown implementation="css">
            <Drawer
              classes={{
                paper: classes.drawerPaper,
              }}
              variant="permanent"
              open
            >
              {drawer}
            </Drawer>
          </Hidden>
        </nav>
        <main className={classes.content}>
          <div className={classes.toolbar} />                    
          <Switch>            
            <Route exact path={applicationPaths.avvik} render={(props) => <Avvikdiagram sisteAntallDager={sisteAntallDager}/>}/>
            <Route path={applicationPaths.innmeldtfra} render={(props) => <Soylediagram dataUri={'hentAntallOrdreGruppertInnmeldtFraSisteAntallDager/' + sisteAntallDager} title='Antall henvendelser'/>}/>
            <Route path={applicationPaths.innogutleveringer} render={(props) => <ReactTable dataUri={'hentLoggForKundeInnOgUtleveringerSisteAntallDager/' + sisteAntallDager}/>}/>
            <Route path={applicationPaths.feil} render={(props) => <ReactTable dataUri={'hentLoggForKundeFeiletSisteAntallDager/' + sisteAntallDager}/>}/>
            <Route path={applicationPaths.logg} render={(props) => <ReactTable dataUri={'hentLoggForKundeSisteAntallDager/' + sisteAntallDager}/>}/>            
            <Route path="*" component={IngenTreff}/>
          </Switch> 
        </main>
      </div>
    );
  }
}

Venstremeny.propTypes = {
  classes: PropTypes.object.isRequired
};

export default compose(
  withRouter,
  withStyles(styles, { withTheme: true })
)(Venstremeny);


 
