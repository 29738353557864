import React, { Component, Fragment } from 'react';
import {
  Typography,
  MenuItem,
  Menu,
  IconButton,
} from '@material-ui/core';
import { withTheme } from '@material-ui/core/styles';
import Icon from 'nka-icons';
import NkaButton from 'nka-button'
import 'nka-button/dist/style.css'

class UserStatus extends Component {
  state = {
    anchorEl: null,
  };

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };
  render() {
    const {
      isLoggedIn,
      userData,
      isLoadingUserData,
      logIn,
      logOut,
    } = this.props;
    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);
    if (isLoadingUserData) {
      return <div>Loading</div>;
    }

    if (isLoggedIn) {
      return (
        <Fragment>
          <IconButton
            aria-owns={open ? 'menu-appbar' : null}
            aria-haspopup="true"
            onClick={this.handleMenu}
            color="inherit"
          >
            <Icon
              icon="bruker"
              color="nk-black"
            />
          </IconButton>
          <Typography variant="body2" color="inherit" noWrap>
            {userData.profile.name} (
            {userData.applicationBackendData.CustomerName})
          </Typography>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={this.handleClose}
          >
            <MenuItem
              onClick={() => {
                this.handleClose();
                logOut();
              }}
            >
              <Typography>Logg ut</Typography>
            </MenuItem>
          </Menu>
        </Fragment>
      );
    }
    return (
      <NkaButton onClick = {logIn}
        buttontype='secondary'>
        Logg inn
      </NkaButton>
    );
  }
}

export default withTheme()(UserStatus);
