import { REACT_APP_APPLICATION_PROFILE } from './appConfig';
import norkartIdJs from 'auth';

export default function Post(dataUri, jsonObject) {

    var returnedToken = norkartIdJs.getToken();
    var httpMethod = 'Post';    

    return new Promise((resolve, reject) => {
        returnedToken.then((token) => {
            fetch(dataUri, postFetchSettings(httpMethod, token, jsonObject))
            .then(res => {
                if(res.ok) {                    
                    resolve(res);
                } else {
                    console.error(res.status);
                    reject("Feilet med status " + res.status);
                }
              })
              .catch(error => {
                  console.error(error);                  
                  reject('Feilet med error: ' + error)                  
              })            
        })
    })
}

function postFetchSettings(httpMethod, token, jsonObject) {
    return {
        method: httpMethod,
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',          
            'Authorization': 'Bearer ' + token,
            'X-WAAPI-PROFILE': REACT_APP_APPLICATION_PROFILE          
        },
        body: JSON.stringify(jsonObject)
    }
} 