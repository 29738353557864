import React, {Component} from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import { REACT_APP_AUTOMASJON_API_URL } from '../../appConfig';
import hentApiData from '../../automasjonApiGet';
import Typography from '@material-ui/core/Typography';
  
class Nokkeltall extends Component {    
    constructor(props) {
        super(props);
        this.state = {
            antall: null,            
            isLoaded: false,
            feilet: false,
            dataUri: props.dataUri,
            color: props.color
        }
    }

    componentDidMount() {
        this.hentData()        
    }

    componentWillReceiveProps(props) { 
        if (this.props.dataUri !== props.dataUri) {      
          this.setState({
            dataUri: props.dataUri,
            isLoaded: false,
            feilet: false,
            color: props.color
          }, () => {
            this.hentData()
          })
        }
      }

    hentData() {
      hentApiData(REACT_APP_AUTOMASJON_API_URL + this.state.dataUri)        
      .then(data => {     
        if (data === 0)
        {          
          this.setState({                
            color: 'primary'            
          });
        }

        this.setState({                
          isLoaded: true,
          feilet: false,
          antall: data                                        
        });
      })
      .catch(err => {      
        this.setState({
            feilet: true,
            isLoaded: true
        });
      })
    }

    render () {      
      
      if(!this.state.isLoaded) {
        return (
          <div>
            <CircularProgress size={25} />
          </div>
        )
      }
      
      if (this.state.feilet) {        
        return null;
      } 

      return (
          <div>
            <Typography color={this.state.color} variant="title" align='right'>
                {this.state.antall}
            </Typography>
          </div>
      );
             
    }
}

export default Nokkeltall;