import React, { Component } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import ToolkitProvider, { Search } from 'react-bootstrap-table2-toolkit';
import { REACT_APP_AUTOMASJON_API_URL } from '../../appConfig';
import Get from '../../automasjonApiGet';
import Moment from 'moment';
import paginationFactory from 'react-bootstrap-table2-paginator';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Icon from 'nka-icons';
import VisBildeDialog from '../Dialog/VisBildeDialog';
import VisTekstDialog from '../Dialog/VisTekstDialog';
import VisOrdreDialog from '../Dialog/VisOrdreDialog';
import CircularProgress from '@material-ui/core/CircularProgress';
import NkaButton from 'nka-button';
import 'nka-button/dist/style.css';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core';


const styles = theme => ({
    root: {    
        paddingBottom: '90px'
    }
})



const kolonner = [
  {
    dataField: 'kommunenr',
    text: 'Knr',
    sort: true,
    sortCaret: (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;↑↓</span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;↑</span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;↓</span>);
    return null;

      }
  },
  {
    dataField: 'ordretype',
    text: 'Henvendelse',
    sort: true,
    sortCaret: (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;↑↓</span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;↑</span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;↓</span>);
    return null;

      }
  },
  {
    dataField: 'beskrivelse',
    text: 'Beskrivelse',
    sort: true,
    sortCaret: (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;↑↓</span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;↑</span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;↓</span>);
    return null;

      }
  },
  {
    dataField: 'innmeldtDato',
    text: 'Innmeldt dato',
    sort: true,
    sortCaret: (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;↑↓</span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;↑</span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;↓</span>);
    return null;

      }
  },
  {
    dataField: 'innmeldtFra',
    text: 'Innmeldt fra',
    sort: true,
    sortCaret: (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;↑↓</span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;↑</span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;↓</span>);
    return null;

      }
  },
  {
    dataField: 'avtaleNr',
    text: 'Avtalenr',
    sort: true,
    sortCaret: (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;↑↓</span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;↑</span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;↓</span>);
    return null;

      }
  },
  {
    dataField: 'adresse',
    text: 'Adresse',
    sort: true,
    sortCaret: (order, column) => {
    if (!order) return (<span>&nbsp;&nbsp;↑↓</span>);
    else if (order === 'asc') return (<span>&nbsp;&nbsp;↑</span>);
    else if (order === 'desc') return (<span>&nbsp;&nbsp;↓</span>);
    return null;

      }
  }
]

const henvendelseTekst = {
    InnleverBeholderInfoModel: 'Innlevering av beholder',
    UtleverBeholderInfoModel: 'Utlevering av beholder',
    BeholderAvvikSkadeInfoModel: 'Skade på beholder',
    BeholderAvvikIkkeToemtInfoModel: 'Ikke tømt beholder',
    BeholderAvvikAnnenInfoModel: 'Annet - avviksmelding',
    StoppepunktKoordinatModel: 'Oppdatering av stoppepunkt-koordinater',
    GenerellMeldingInfoModel: 'Avvik til oppfølging'
}; 

const { SearchBar } = Search;

const visOrdreKnapp = (matrikkelnr, ordrenr) => {    
    return (
        <VisOrdreDialog ordrenr={ordrenr} matrikkelnr={matrikkelnr}/>
    );
}

const hentValgfriStatustekstKnapp = (valgfriStatustekst) => {
    
    if (!valgfriStatustekst)
        return;

    return <VisTekstDialog text={valgfriStatustekst} buttonTitle="Mer info"/>;
}

const hentBildeKnapp = (bildeUrl) => {

    if (!bildeUrl) { return; }    
    
    return <VisBildeDialog bildeUrl={bildeUrl} buttonTitle="Vis bilde"/>;
}

const hentKartKnapp = (statiskKartUrl) => {
    
    if (!statiskKartUrl) { return; } 

    return <VisBildeDialog bildeUrl={statiskKartUrl} buttonTitle="Vis i kart"/>;
}

const expandRow = {  
    renderer: row => (                    
        <div>     
            <Grid container>
                <Grid item xs={3}>                
                    <Typography><strong>Innmeldt av: </strong></Typography>
                    <Typography>{row.eier}</Typography>
                    <Typography>Epost: {row.epost}</Typography>
                    <Typography>Telefon: {row.telefon}</Typography>                                                
                </Grid>
                <Grid item xs={5}>
                    <Typography><strong>Statuslogg:</strong></Typography>                
                        {
                        row.historikk.map(historikk => {
                            return (
                                <div>
                                    <Grid container alignItems='center'>
                                        <Grid item><Typography>{historikk.endretDatoDisplay} - {historikk.status}</Typography></Grid>                                        
                                        <Grid item>{hentValgfriStatustekstKnapp(historikk.valgfriStatustekst)}</Grid>                                        
                                    </Grid>
                                </div>                                
                            )
                        })
                        }                
                </Grid>            
                <Grid item xs={4}>
                    <Typography><strong>Merknad: </strong> {row.merknad}</Typography>                
                </Grid>                
            </Grid>
            
            <Grid container>
                <Grid item>
                    {visOrdreKnapp(row.matrikkelnr, row.ordrenr)}
                </Grid>
                <Grid item>
                    {hentBildeKnapp(row.bildeUrl)}
                </Grid>
                <Grid item>
                    {hentKartKnapp(row.statiskKartUrl)}
                </Grid>
            </Grid>
            
        </div>
    ),    
    showExpandColumn: true,
    expandHeaderColumnRenderer: ({ isAnyExpands }) => {
        if (isAnyExpands) {
            return ( <Icon icon="minus" size="1"/> );
        }
        return ( <Icon icon="pluss" size="1"/> );
      },
    expandColumnRenderer: ({ expanded }) => {
        if (expanded) {
            return ( <Icon icon="opp" size="1"/> );
        }
        return ( <Icon icon="ned" size="1"/> );
    }
  };

const customTotal = (from, to, size) => (
    <span> Viser { from } til { to } av { size }</span>
  );

const paginationOptions = (tabellData) => ({    
    showTotal: true,
    paginationTotalRenderer: customTotal,    
    sizePerPageList: [{
        text: '10', value: 10
    }, {
        text: '25', value: 25
    }, {        
        text: 'Alle', value: tabellData.length
    }] // A numeric array is also available. the purpose of above example is custom the text    
});

const exportStyle = {
    flex: {
        flexGrow: 1,   
    }
};

const ExportCSVButton = (props) => {
    const handleClick = () => {
      props.onExport();
    };
    var knappetekst = "Last ned alle (" + props.antall + ")";
    return (
        <div>          
            <NkaButton onClick={handleClick} buttontype='secondary' icon="rapport">{knappetekst}</NkaButton>
        </div>
    );
};


class ReactTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tabellKolonner: kolonner,
            tabellData: [],
            isLoaded: false,
            feiltekst: null,
            dataUri: props.dataUri
        }
      }

      hentHenvendelseTekst = (ordretype) => {
        return henvendelseTekst[ordretype];
      }

      componentDidMount() {
        this.hentData();
      }

      componentWillReceiveProps(props) {
        if (this.props.dataUri !== props.dataUri) {
          this.setState({
            dataUri: props.dataUri,
            isLoaded: false,
            feiltekst: null,
            tabellData: []
          }, () => {
            this.hentData()
          })
        }
      }

      hentData() {
        Get(REACT_APP_AUTOMASJON_API_URL + this.state.dataUri)
        .then(data => {
          this.settData(data)
          this.setState({
            isLoaded: true,
            feiltekst: null
          })
        })
        .catch(err => {      
            this.setState({
                feiltekst: "Henting av data feilet. Prøv igjen eller kom tilbake senere.",
                isLoaded: true
            })
        })
      }


      settData(data) {
        Moment.locale('no');

        Object.entries(data).forEach(([key,value]) => {
          var item = data[key];          

          if (typeof(item) === 'object')
          {
            Object.entries(item).forEach(([key,index]) => {
              var ordreLogg = item[key].ordreLogg;

              let historikkListe = [];
              Object.entries(ordreLogg.historikk).forEach(([key, index]) => {
                historikkListe.push(
                    ordreLogg.historikk[key]
                )
              })

              var ordredata = JSON.parse(ordreLogg.ordredata);

              var kartUrl = null;  
              if (ordredata != null && ordredata.XKoordinat > 0 && ordredata.YKoordinat > 0) {
                var coordSys = "32632";
                var apiKey = "nk_statisk_kart";
                kartUrl = "https://www.webatlas.no/WAAPI-StatiskKart/punkt/?X=" 
                              + ordredata.XKoordinat
                              + "&Y="
                              + ordredata.YKoordinat
                              + "&PikslerPerMeter=0.5&SkjulMarkor=false&"
                              + "&&&SRS="
                              + coordSys
                              + "&Hoyde=500&Bredde=500&KartType=street&api_key="
                              + apiKey;
              }
              

              var data = {
                id: key,
                kommunenr: item[key].kommunenr,
                matrikkelnr: item[key].matrikkelnr,
                ordrenr: ordreLogg.ordreNr,
                ordretype: this.hentHenvendelseTekst(ordreLogg.ordretype),
                beskrivelse: ordreLogg.beskrivelse,
                innmeldtDato: Moment(ordreLogg.innmeldtDato).format('DD.MM.YYYY HH:mm'),
                innmeldtFra: ordreLogg.innmeldtFra,
                avtaleNr: ordreLogg.avtaleNr,
                adresse: ordreLogg.adresse,
                merknad: ordreLogg.merknad,
                eier: ordreLogg.innmeldtAv,
                epost: ordreLogg.epost,
                telefon: ordreLogg.telefon,
                bildeUrl: ordreLogg.bildeUrl,
                statiskKartUrl: kartUrl,
                historikk: historikkListe
              }

              this.state.tabellData.push(data);
            })
          }
        })
      }

  render() {
    const { classes } = this.props;

    if(!this.state.isLoaded) {
        return (
            <div>
                <CircularProgress />
                <Typography>Henter innhold</Typography>
            </div>
        )
    }
    
    if (this.state.feiltekst) {
        return <Typography color="error">{this.state.feiltekst}</Typography>
    }

    return (
    <div>
        <ToolkitProvider                
            keyField="id"
            data={ this.state.tabellData }
            columns={ this.state.tabellKolonner }
            search
            exportCSV=
            {
                {
                    noAutoBOM: false,
                    fileName: "Eksport.csv"
                }
            }
        >
        {
            props => (                
            <div className="table-responsive">
                <div className={classes.root}>
                    <Grid container alignItems="center" direction="row" justify="space-between">
                        <Grid item xs={12} md={8}>
                            <SearchBar
                                { ...props.searchProps }
                                placeholder="Søk" />
                        </Grid>                        
                        <Grid item xs={12} md={3}>
                            <ExportCSVButton className={exportStyle.flex} { ...props.csvProps } antall = {this.state.tabellData.length}/>
                        </Grid>                        
                    </Grid>            
                    
                    <Grid container>
                        <Grid item>
                            <BootstrapTable                        
                                { ...props.baseProps }                                
                                striped
                                hover
                                bordered={ false }
                                expandRow={ expandRow }                                
                                pagination={ paginationFactory(paginationOptions(this.state.tabellData)) }
                                noDataIndication="Ingen data å vise"
                            />                        
                        </Grid>
                    </Grid>
                    
                </div>
            </div>
            )
        }
        </ToolkitProvider>
    </div>
    );
    }
}


ReactTable.propTypes = {
    classes: PropTypes.object.isRequired,
  };

export default withStyles(styles)(ReactTable);