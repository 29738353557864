import React, { Fragment } from 'react';
import { CssBaseline, withStyles } from '@material-ui/core';
import flowRight from 'lodash/flowRight';
import { BrowserRouter as Router} from 'react-router-dom';
import Header from './Header';
import auth from 'auth';
import authWrapper from 'authWrapper';
import Venstremeny from '../Components/Venstremeny/Venstremeny'
import LandingPage from '../App/LandingPage/LandingPage'

const styles = theme => ({
  root: {
    flexGrow: 1,
    zIndex: 1,
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
  },
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,    
    minWidth: 0, // So the Typography noWrap works
  },
});

//isAuthenticated and isAuthorized is supplied from the authWrapper HOC

//this is where you would handle routing
const App = ({ classes, isAuthenticated, isAuthorized }) => (        
  <Router>
    <Fragment>
      <CssBaseline />      
      <Header/>      
      <div className={classes.root}>      
        <main className={classes.content}>
          {/* Check if logged in or not */}
          {
            isAuthenticated && isAuthorized ? 
            ( <Venstremeny /> ) 
            : ( <LandingPage/> )
          }          
        </main>        
      </div>
    </Fragment>  
  </Router>
);

const enhance = flowRight(
  authWrapper(auth),
  withStyles(styles)
);
export default enhance(App);
